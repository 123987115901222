import { FifthDiv, FifthText, FifthSmallText, FifthSpans, EndingButton } from "../styles/styles";
// import conservacao_fundo from './../assets/conservacao_fundo.png';
// import conservacao_corujinha from './../assets/conservacao_corujinha.png';
import passaro from './../assets/passaro.png'
import passaromobile from './../assets/passaromobile.png'   
import styled from "styled-components";
import play from '../assets/play.png';
import { Parallax, ParallaxProvider }  from 'react-scroll-parallax';
import Fade from 'react-reveal/Fade';


const Fifth = () => {
    const Image = styled.img`
        position: absolute;
        z-index: 1;
        top: 0;
        right: 0;
        width: 500px;
        @media (max-width: 1100px) {
            display: none;
        }
    `

    const ImageDiv = styled.div`
        position: absolute;
        z-index: 1;
        top: 0;
        right: 0;
        width: 100%;
        margin-top: 200px;
        @media (max-width: 1100px) {
            display: none;
        }
    `

    const ImageMobile = styled.img`
        display: none;
        @media (max-width: 1100px) {
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            margin-right: -25px;
            margin-top: -160px;
            margin-bottom: -100px;
        }
        @media(min-width: 768px) and (max-width: 1100px) {
            width: 360px;
            margin-top: 100px;
        }
        `

    const SmallText = styled.div`
        width: 58%;
        text-align: center;
        margin-top: 10px;
        @media (max-width: 1100px) {
            width: 35%;
        }
    `

    const AccessButton = styled(EndingButton)`
        margin: 0;
        margin-top: 50px;
        width: 300px;
        height: 40px;
        font-size: 20px;
        z-index: 3;
        @media (max-width: 1100px) {
            width: 210px;
            height: 25px;
            font-size: 13px;
            border-radius: 5px;
            margin-top: -40px;
        }
    `

    const redirectToChannel = () => {
        window.open('https://www.youtube.com/playlist?list=PLCfCq9L_Bz3G7pLm1oIpwY8Y21lcyEOh2', '_blank');
    }

    return (
        <FifthDiv style={{padding: '100px 0 0'}}>
            
            <ParallaxProvider>
            <Fade top distance={"30px"}>
            {/* <img src={conservacao_fundo} alt="" style={{position:'absolute', zIndex: '-1', width:'100%', height:'100%'}}/> */} 
            <FifthSpans>
                <FifthText>A CONSERVAÇÃO</FifthText>
                <FifthText>DO MEIO AMBIENTE</FifthText>
                <FifthText>É PARTE FUNDAMENTAL</FifthText>
                <FifthText>DOS VALORES DA BRACELL</FifthText>
                <SmallText>
                    <FifthSmallText>Assista aos vídeos e surpreenda-se com as belezas naturais do <span style={{fontWeight: '700', color: '#5A954C'}}>Litoral Norte e Agreste da Bahia</span></FifthSmallText>  
                </SmallText>
            </FifthSpans>
            <AccessButton onClick={() => redirectToChannel()}>Quero me surpreender <img src={play} style={{backgroundColor: '#101612', clipPath: 'polygon(100% 50%, 0 0, 0 100%)', zIndex: 100}} alt="" /></AccessButton>
            </Fade>
                <ImageDiv>
            <Parallax speed={60}>
                    <Image src={passaro} alt="" />
            </Parallax>
                </ImageDiv>
            <Parallax speed={0.5}>
                <div style={{width: '100vw', height:'100px'}}>
                    <ImageMobile src={passaromobile} alt=""/>
                </div>
            </Parallax>
            </ParallaxProvider>
        </FifthDiv>
     );
}
 
export default Fifth;
