import exposicao from './../assets/exposicao.jpeg';
import styled from 'styled-components'

const Exposition = () => {

    const ExpositionDiv = styled.div`
        width: 100vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #ffffff;
        color: #000000;;
    `

    const FirstPart = styled.div`
        width: 1000px;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        padding: 40px;
        @media (max-width: 1100px) {
            flex-direction: column-reverse;
        }
    `

    const LeftTexts = styled.div`
        width: 700px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        line-height: 2.5;
        margin-right: 50px;
        text-align: justify;
        @media (max-width: 1100px) {
            line-height: 1.5;
            width: calc(100vw - 80px);
            margin: 0 40px;
            height: 300px;
            line-height: 2;
            margin-top: 50px;
        }
    `

    const DateDiv = styled.div`
        width: 80%;
        height: 200px;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        @media (max-width: 1100px) {
            flex-direction: column;
            height: 300px;
        }

    `



    return ( 
    <ExpositionDiv>
        <FirstPart>
            <LeftTexts>
                <p>Em 2022, o projeto ultrapassou as páginas do livro e expandiu para uma exposição sensorial, que vai acontecer de forma itinerante.</p>
                <p><strong>Confira as datas da Exposição Floresta Sempre Viva e viva a floresta de perto:</strong></p>
            </LeftTexts>
            <img style={{width: '400px'}} src={exposicao} alt="" />
        </FirstPart>
        <DateDiv>
            <Date2 date="04/10 a 18/10" place='Shopping da Bahia' place2='Praça Mãe Menininha do Gantois, piso L3' city="SALVADOR" />
            <Date2 date='10 a 15 DE NOVEMBRO' place='Bienal do Livro Bahia 2022' place2='Centro de Convenções' city="SALVADOR" />
        </DateDiv>

    </ExpositionDiv>
     );
}
 
const Date = (props) => {

    const DateDate = styled.p`
        font-size: 16px;
        font-weight: 600;
        color: #7EAD71;
    `

    const DatePlace = styled.p`
        font-size: 16px;
        font-weight: 500;
    `

    const DateCity = styled.p`
        font-size: 16px;
        font-weight: 700;
    `

    return (
        <div style={{display: 'flex', flexDirection:'column', textAlign: 'center'}}>
            <DateDate>{props.date}</DateDate>
            <DatePlace>{props.place}</DatePlace>
            <DateCity>{props.city}</DateCity>
        </div>
    )
}
 
const Date2 = (props) => {

    const DateDate = styled.p`
        font-size: 16px;
        font-weight: 600;
        color: #7EAD71;
    `

    const DatePlace = styled.p`
        font-size: 16px;
        font-weight: 500;
    `

    const DatePlace2 = styled.p`
        font-size: 16px;
        font-weight: 500;
    `

    const DateCity = styled.p`
        font-size: 16px;
        font-weight: 700;
    `

    return (
        <div style={{display: 'flex', flexDirection:'column', textAlign: 'center'}}>
            <DateDate>{props.date}</DateDate>
            <DatePlace>{props.place}</DatePlace>
            <DatePlace2>{props.place2}</DatePlace2>
            <DateCity>{props.city}</DateCity>
        </div>
    )
}
export default Exposition;