import Header from "./pages/Header";
import First from "./pages/First";
import Second from "./pages/Second";
import Third from "./pages/Third";
import WhiteStrip from "./pages/WhiteStrip";
import Fifth from "./pages/Fifth";
import Sixth from "./pages/Sixth";
import SecondWS from "./pages/SecondWS";
// import Ending from "./pages/Ending";
import Footer from "./pages/Footer";
import Exposition from "./pages/Exposition";

function App() {
  return (
    <div className="App" style={{width: "100vw"}}>
      <Header/>
      <First/>
      <Third/>
      <Second/>
      <Fifth/>
      <Sixth/>
      <SecondWS/>
      <WhiteStrip/>
      {/* <Ending/> */}
      <Exposition/>
      <Footer/>
    </div>
  );
}

export default App;
