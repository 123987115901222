import { Surpreenda, SixthDiv, LeftDiv, QuoteAuthor, LeftText, LeftSpans, PopUpDiv, LeftTitle, SeventhDiv, RightDiv, QuoteDiv, QuoteDivMobile, Quote, SevenRight, SevenLeft, SevenLeftText, SevenLeftTitle, SevenRightText, BaixarButton } from '../styles/styles';

import { useState } from 'react';

import playerImg from "../assets/player.svg";
import quotemark from '../assets/quotemark.png';
// import gamba from './../assets/gamba.png';
// import pedra from './../assets/pedra.png';
// import mico from './../assets/mico.png';
// import camaleao from './../assets/camaleao.png';
import first_background from './../assets/surpreenda_fundo_1.png';
import second_background from './../assets/surpreenda_fundo_2-overlay.png';
import shadow from './../assets/shadow.png';
import floresta_sempre_viva_versao_digital from './../assets/floresta_sempre_viva_versao_digital.pdf';

import sixth_forest from './../assets/sixth_forest.png';
import sixth_snake from './../assets/sixth_snake.png';
import sixth_water from './../assets/sixth_water.png';
import sixth_couple from './../assets/sixth_couple.png';

import styled from 'styled-components';

import { Parallax, ParallaxProvider } from "react-scroll-parallax";
import Fade from 'react-reveal/Fade';

const Sixth = () => {

    const Shadow = styled.img`
        position: absolute;
        z-index: 1;
        width: 100%;
        overflow: hidden;
        height: 1650px;
        @media (max-width: 1100px) {
            margin-top: 50%;
            height: 1850px;
            width: 150%;
        }
        @media (min-width: 768px) and (max-width: 1100px) {
            height: 2450px;
        }
            `

    return ( 
        <Surpreenda>
            <Shadow src={shadow} alt="" />
            <FirstPart/>
            <SecondPart/>
        </Surpreenda>
     );
}

const Images = styled.img`
    transition: 0.3s all;
    z-index: 2;
    width: 400px;
    @media (max-width: 1100px) {
        width: 290px;
        margin: 0 0%;
        margin-bottom: 5%;
    }
    @media (min-width: 768px) and (max-width: 1100px) {
        width: 500px;
    }
    &:hover {
        transform: scale(1.06);
    }
`

const ImageDiv = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    height: fit-content;
    width: fit-content;
    position: relative;
    z-index: -1;
    transition: 0.3s all;
    @media (max-width: 1100px) {
        width: 100vw;
        align-items: center;
        justify-content: center;
        align-items: flex-end;
    }
    &:hover {
        transform: scale(1.06);
    }
`


const SecondPartPlayerImage = styled.img`
    position: absolute;
    z-index: 2;
    opacity: 0.8;
    margin-top: 1%;
    margin-left: 1%;
    @media (max-width: 1100px) {
        margin-left: 70px;
        width: 32px;
        top: 0;
        left: 0;
        margin-top: 10px
    }
    @media (min-width: 768px) and (max-width: 1100px) {
        margin-left: calc(50vw - 210px);
        width: 32px;
        top: 0;
        left: 0;
        margin-top: 10px;
    }
`

const PopupIframe = styled.iframe`
    position: absolute;
    top: 0;
    left: 0;
    width: 90%;
    height: 90%;
    border: none;
    margin: 3% 0 0 3%;
    zIndex: 2;
    @media (max-width: 1100px) {
        width: 80%;
        height: 40vh;
        margin-top: 30vh;
        margin-left: 10%;
    }
`

const CloseButton = styled.div`
    position: absolute;
    font-size: 32px;
    color: #EFEFEF;
    top: 0;
    right: 0;
    z-index: 2;
    margin-top: 10px;
    margin-right: 20px;
    cursor: pointer;
    @media (max-width: 1100px) {
        margin-right: 10px;
    }
`

const ClickableDiv = styled.div`
    cursor: pointer;
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
`

const FirstPart = () => {


    const FirstBackground = styled.img`
        position: absolute;
        z-index: 2;
        margin-top: -100px;
        width: 100vw;
        @media (min-width: 1100px) {
            height: 1000px;
        }
        @media (max-width: 1100px) {
            display: none;
            width: 130vw;
            margin-left: -50vw;
            margin-top: 10%;
        }
    `

    const SecondBackgroundMobile = styled.img`
        position: absolute;
        z-index: -2;
        margin-top: -7vh;
        width: 100%;
        display: none;
        @media (max-width: 768px) {
            width: 200vw;
            display: flex;
            margin-left: -90vw;
            margin-top: 35%;
        }
    `

    const QuoteMark = styled.img`
        width: 64px;
        height: 64px;
        z-index: -1;
        margin: 0 0 -30px -40px;
        @media (max-width: 1100px) {
            width: 45px;
            height: 30px;
            margin: 0 0 -15px -30px;
        }
    `

    const ImageGamba = styled.img`
        margin-bottom: 7%;
        cursor: pointer;
        position: relative;
        z-index: 0;
        transition: 0.3s all;
        width: 492px;
        @media (min-width: 1100px) {
            height: 385px;
        }
        @media (max-width: 1100px) {
            width: 290px;
            margin: 20px 0 0 0;
        }
        @media (min-width: 768px) and (max-width: 1100px) {
            width: 500px;
        }
        &:hover {
            transform: scale(1.06);
        }
    `

    const ImageMico = styled.img`
        margin-right: 10px;
        z-index: 0;
        cursor: pointer;
        transition: 0.3s all;
        @media (min-width: 1100px) {
            height: 319px;
        }
        @media (max-width: 1100px) {
            width: 290px;
            height: 150px;
            margin: 0;
        }
        @media (min-width: 768px) and (max-width: 1100px) {
            width: 500px;
        }
        &:hover {
            transform: scale(1.06);
        }
    `

    const ImageCamaleao = styled.img`
        cursor: pointer;
        transition: 0.3s all;
        width: 462px;
        @media (min-width: 1100px) {
            height: 360px;
        }
        @media (max-width: 1100px) {
            width: 290px;
            margin-left: 0;
            z-index: 0;
        }
        @media (min-width: 768px) and (max-width: 1100px) {
            width: 500px;
        }
        &:hover {
            transform: scale(1.06);
        }
    `

    const PlayerImage = styled.img`
        position: absolute;
        z-index: 1;
        opacity: 0.8;
        margin-top: 1%;
        margin-left: 1%;
        @media (max-width: 1100px) {
            margin-left: 70px;
            width: 32px;
            top: 0;
            left: 0;
            margin-top: 10px;
        }
        @media (min-width: 768px) and (max-width: 1100px) {
            margin-left: calc(50vw - 210px);
            width: 32px;
            top: 0;
            left: 0;
            margin-top: 10px;
        }
    `
    
    const EndPlayerImage = styled.img`
        position: absolute;
        z-index: 1;
        opacity: 0.8;
        top: 0;
        left: 0;
        margin-top: 310px;
        margin-left: 420px;
        @media (max-width: 1100px) {
            width: 32px;
            margin: 140px 310px;
            // margin-left: calc(50vw + 100px);
            // margin-top: calc(30%);
        }
        @media (min-width: 768px) and (max-width: 1100px) {
            width: 32px;
            margin: 250px 710px;
        }
    `

    const [popUpContent, setPopUpContent] = useState('');
    const [showPopUp, setShowPopUp] = useState(false);

    const FshowPopUp = (content) => {
        console.log(content)
        setPopUpContent(content);
        setShowPopUp(true);
        console.log(popUpContent)
    }

    const FhidePopUp = () => {
        setShowPopUp(false);
    }

    const gambaPopup = (
        <div style={{marginTop: '-50px', width: '100%', height: '80%', display: 'flex', alignItems:'center', justifyContent:'space-around', flexDirection:'column'}}>
            <PopupIframe
                src={`https://www.youtube.com/embed/EmrcAhI0bn4`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Embedded youtube"
            />
        </div>)

    const micoPopup = (
        <div style={{marginTop: '-50px', width: '100%', height: '80%', display: 'flex', alignItems:'center', justifyContent:'space-around', flexDirection:'column'}}>
            <PopupIframe
                src={`https://www.youtube.com/embed/I6_7vdZQliI`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Embedded youtube"
            />
        </div>)

    const camaleaoPopup = (
        <div style={{marginTop: '-50px', width: '100%', height: '80%', display: 'flex', alignItems:'center', justifyContent:'space-around', flexDirection:'column'}}>
            <PopupIframe
                src={`https://www.youtube.com/embed/xpczVboczf0`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Embedded youtube"
            />
        </div>)
        
    return (
        <SixthDiv id='surpreenda'>
            <ParallaxProvider>


            {showPopUp &&
                <div>    
                    <div onClick={FhidePopUp} style={{ position: 'fixed', top:'0', left: '0', width: '100vw', height: '100vh', zIndex: '3'}} />
                    <PopUpDiv id='popup'>
                        <div style={{width: '100%', height: '100%',  position:'relative'}}><CloseButton onClick={FhidePopUp}>X</CloseButton></div>
                        {popUpContent}
                    </PopUpDiv>
                </div>
            }
            <Parallax speed={80} style={{zIndex: '2'}}>
                {/* <div style={{width: '100vw', height:'fit-content', zIndex: '-1'}}> */}

                    <FirstBackground src={first_background} alt=""/>
                {/* </div> */}
            </Parallax>
            <LeftDiv>
                <Fade top distance={"30px"}>    

                <LeftSpans>
                    <LeftText>Vida faz barulho, tem movimento, cor e textura.</LeftText>
                    <LeftText>Vida é diversidade e renovação.</LeftText>
                    <LeftTitle><span style={{color: '#EFEFEF'}}>SURPREENDA</span>(SE)</LeftTitle>
                </LeftSpans>
                    <ImageDiv>
                        <EndPlayerImage src={playerImg}  alt=''  />
                        <ImageGamba onClick={() => FshowPopUp(gambaPopup)} src={sixth_forest} alt="" />
                        <ClickableDiv onClick={() => FshowPopUp(gambaPopup)} style={{height: '92%'}}/>
                    </ImageDiv>
                <QuoteDivMobile className="quote">
                    <QuoteMark src={quotemark} alt="" />
                    <Quote>Se eu pudesse, vinha <span style={{fontWeight: '600', color: "#5A954C", fontFamily: "'Azo-Sans', sans-serif"}}> todos os dias</span>, porque aqui a gente sempre descobre uma coisa nova. A cada ronda vejo uma <span style={{fontWeight: '600', color: "#5A954C",  fontFamily: "'Azo-Sans', sans-serif"}}>árvore</span> ou um <span style={{fontWeight: '600', color: "#5A954C",  fontFamily: "'Azo-Sans', sans-serif"}}>bicho</span> que nunca tinha visto".</Quote>
                    <QuoteAuthor>Diones "John" - Vigilante na RPPN Lontra</QuoteAuthor>
                </QuoteDivMobile>
                <SecondBackgroundMobile src={first_background} alt=""/>
                    <ImageDiv >
                        <PlayerImage src={playerImg}  alt='' />
                        <ImageMico src={sixth_snake} alt=""  />
                        <ClickableDiv onClick={() => FshowPopUp(micoPopup)}/>
                    </ImageDiv>
                </Fade>
            </LeftDiv>
            <RightDiv>
                <Fade top  distance={"30px"}>
                <QuoteDiv className="quote">
                    <QuoteMark src={quotemark} alt="" />
                    <Quote>Se eu pudesse, vinha <span style={{fontWeight: '600', color: "#5A954C", fontFamily: "'Azo-Sans', sans-serif"}}> todos os dias</span>, porque aqui a gente sempre descobre uma coisa nova. A cada ronda vejo uma <span style={{fontWeight: '600', color: "#5A954C",  fontFamily: "'Azo-Sans', sans-serif"}}>árvore</span> ou um <span style={{fontWeight: '600', color: "#5A954C",  fontFamily: "'Azo-Sans', sans-serif"}}>bicho</span> que nunca tinha visto".</Quote>
                    <QuoteAuthor>Diones "John" - Vigilante na RPPN Lontra</QuoteAuthor>
                </QuoteDiv>
                    
                    <ImageDiv>
                        <SecondPartPlayerImage src={playerImg}  alt='' />
                        <ImageCamaleao src={sixth_water} alt="" />
                        <ClickableDiv onClick={() => FshowPopUp(camaleaoPopup)}/>
                    </ImageDiv>
                </Fade>
            </RightDiv>
            </ParallaxProvider>
        </SixthDiv>
    );
}

const SecondPart = () => {

    const goToBaixar = () => {
        window.open(floresta_sempre_viva_versao_digital, '_blank');
    }

    const [popUpContent, setPopUpContent] = useState('');
    const [showPopUp, setShowPopUp] = useState(false);

    const FshowPopUp = (content) => {
        console.log(content)
        setPopUpContent(content);
        setShowPopUp(true);
        console.log(popUpContent)
    }

    const FhidePopUp = () => {
        setShowPopUp(false);
    }

    const riachoPopup = (
        <div style={{marginTop: '-50px', width: '100%', height: '80%', display: 'flex', alignItems:'center', justifyContent:'space-around', flexDirection:'column'}}>
            <PopupIframe
                src={`https://www.youtube.com/embed/xPu-CFAZHD8`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Embedded youtube"
            />
        </div>)

    const SecondBackground = styled.img`
        position: absolute;
        z-index: 0;
        width: 100%;
        margin-top: -350px;
        height: 990px;
        @media (max-width: 1100px) {
            width: 200vw;
            margin-left: -50%;
            margin-top: -150px;
            height: 750px;
            z-index: 0;
        }
        @media (min-width: 768px) and (max-width: 1100px) {
            height: 900px;
            margin-top: -300px;
        }
    `        

    const PlayerRiacho = styled.img`
        position: absolute;
        z-index: 4;
        opacity: 0.8;
        margin-top: 1%;
        top: 0;
        left: 0;
        margin-left: 1%;
        @media (max-width: 1100px) {
            top: 0;
            left: 0;
            margin-left: 70px;
            margin-top: 10px;
            width: 32px;
        }
        @media (min-width: 768px) and (max-width: 1100px) {
            width: 32px;
            top: 0;
            left: 0;
            margin: 0 0 0 0;
            margin-left: calc(50vw + 100px);
            margin-top: 360px;
        }
    `

    return (
        <ParallaxProvider>

        <SeventhDiv>
                {showPopUp &&
                    <div>    
                        <div onClick={FhidePopUp} style={{ position: 'fixed', top:'0', left: '0', width: '100vw', height: '100vh', zIndex: '3'}} />
                        <PopUpDiv id='popup'>
                            <div style={{width: '100%', height: '100%',  position:'relative'}}><CloseButton onClick={FhidePopUp}>X</CloseButton></div>
                            {popUpContent}
                        </PopUpDiv>
                    </div>
                }
                <Parallax speed={-5} style={{width:'100vw', height: '700px', position: 'absolute'}}>

                <SecondBackground src={second_background} alt="" />
                </Parallax>
    

                <SevenLeft>
                    <div style={{display: 'flex', flexDirection:'column', height: '27%', justifyContent: 'space-around', marginBottom: '40%', marginLeft: '35%'}}>             
                        <SevenLeftTitle>(RE)<span style={{color: '#efefef'}}>CONHEÇA</span></SevenLeftTitle>
                        <SevenLeftText>as belezas naturais da Bahia através dessa representação da força da natureza e da beleza de seus habitantes:</SevenLeftText>
                    </div>
                </SevenLeft>
                <SevenRight>
                    
                        <ImageDiv>
                            <PlayerRiacho src={playerImg}  alt=''  />
                            <Images src={sixth_couple} alt="" />         
                            <ClickableDiv onClick={() => FshowPopUp(riachoPopup)}/>
                        </ImageDiv>
                        <SevenRightText>O livro <span style={{fontWeight:700}}>Floresta Sempre Viva</span> oportuniza a todos conhecer essa <span style={{fontWeight:700}}> riqueza natural</span> registrada a partir dos olhares daqueles que a<span style={{fontWeight:700}}> protegem</span> diariamente.</SevenRightText>
                    <BaixarButton onClick={() => goToBaixar()}>BAIXAR</BaixarButton>
                </SevenRight>
            </SeventhDiv>
        </ParallaxProvider>
    );
}


export default Sixth;