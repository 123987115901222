
// import respira from './../assets/respira.png'
import down_arrow from './../assets/down_arrow.png'
import { Respira, Image, MainGIF, Title, FlorestaCarrousel, Bold900, Carrousel, CarrouselText, CarrouselDiv, DownArrow, CarrouselItem, Bold900M } from './../styles/styles'
import gif from './../assets/video.gif'
import styled, { keyframes } from 'styled-components'

const First = () => {

    const scroolTo = (id) => {
        document.getElementById(id).scrollIntoView({ 
            behavior: 'smooth' 
        });
    }

    
    const LeftAnimation = keyframes`
        0%, 10% {
            transform: translateX(0);
        }
        14%,26% {
            transform: translateX(0);
        }
        30%,42% {
            transform: translateX(0);
        }
        46%,58% {
            transform: translateX(0);
        }
        62%, 70% {
            transform: translateX(0);
        }
        72%, 99% {
            transform: translateX(-72px);
        }
        100% {
            transform: translateX(0);
        }
    `



    const Bold900A = styled(Bold900)`
        animation: ${LeftAnimation} 12s infinite;
    `

    const CarrouselDivA = styled(CarrouselDiv)`
        margin-top: -30px;
        margin-left: 0px;
        @media (max-width: 1100px) {
            margin-top: -15px;
            margin-left: 0px;
        }
    `

    const BracelApresentaText = styled(CarrouselText)`
        margin-top: 5px;
        font-size: 20px;
        @media (max-width: 1100px) {
            font-size: 8px;
        }
    `

    const CarrouselDivB = styled(CarrouselDiv)`
        margin-top: 70px;
        margin-left: 0px;
        @media (max-width: 1100px) {
            margin-top: 20px;
            margin-left: 0px;
        }
    `
    const TextMobile = styled.div`
        color: #fff;
        margin-top: 5px;
        font-size: 16px !important;
        margin-bottom: 10px !important;
        font-size: 64px;
        font-weight: 500;
        display: none;
        @media (max-width: 1100px) {
            font-size: 21px;
            font-size: 8px;
            display: inline;
        }
    `

    const MobileTitle = styled.div`
        width: 100%;
        height: 20%;
        margin-left: 12.5%;
        color: #ffffff;
        display: flex;
        flex-direction: column;
        align-items: left;
    `

    return ( 
        <Respira>
            {/* <Image autoPlay muted loop> */}
                {/* <source src={video} type="video/mp4"/> */}
            {/* </Image> */}
            <Image src={gif} />
            <MainGIF src={gif} alt=""/>
            <Title style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
            <CarrouselDivA>
                    <Carrousel>
                        <CarrouselItem><CarrouselText style={{marginLeft: '-1000px'}}> .</CarrouselText></CarrouselItem>
                        <CarrouselItem><CarrouselText style={{marginLeft: '-1000px'}}> .</CarrouselText></CarrouselItem>
                        <CarrouselItem><CarrouselText style={{marginLeft: '-1000px'}}> .</CarrouselText></CarrouselItem>
                        <CarrouselItem><CarrouselText style={{marginLeft: '-1000px'}}> .</CarrouselText></CarrouselItem>
                        <CarrouselItem><CarrouselText style={{marginLeft: '-1000px'}}> .</CarrouselText></CarrouselItem>
                        <CarrouselItem><BracelApresentaText>BRACELL APRESENTA:</BracelApresentaText></CarrouselItem>
                    </Carrousel>
                </CarrouselDivA>
                <FlorestaCarrousel>
                    <Bold900A >A Floresta</Bold900A>
                </FlorestaCarrousel>
                <CarrouselDiv>
                    <Carrousel>
                        <CarrouselItem><CarrouselText>Respira</CarrouselText></CarrouselItem>
                        <CarrouselItem><CarrouselText>Renova</CarrouselText></CarrouselItem>
                        <CarrouselItem><CarrouselText>Inspira</CarrouselText></CarrouselItem>
                        <CarrouselItem><CarrouselText style={{marginTop: '2%'}}>Vive</CarrouselText></CarrouselItem>
                    </Carrousel>
                </CarrouselDiv>
                <CarrouselDivB>
                    <Carrousel>
                        <CarrouselItem><CarrouselText style={{marginLeft: '-1000px'}}> .</CarrouselText></CarrouselItem>
                        <CarrouselItem><CarrouselText style={{marginLeft: '-1000px'}}> .</CarrouselText></CarrouselItem>
                        <CarrouselItem><CarrouselText style={{marginLeft: '-1000px'}}> .</CarrouselText></CarrouselItem>
                        <CarrouselItem><CarrouselText style={{marginLeft: '-1000px'}}> .</CarrouselText></CarrouselItem>
                        <CarrouselItem><CarrouselText style={{marginLeft: '-1000px'}}> .</CarrouselText></CarrouselItem>
                        <CarrouselItem><Bold900 style={{marginTop: '5px'}}>sempre viva</Bold900></CarrouselItem>
                    </Carrousel>
                </CarrouselDivB>
            </Title>
            <MobileTitle>
                <TextMobile>BRACELL APRESENTA:</TextMobile>
                <Bold900M>Floresta</Bold900M>
                <Bold900M>sempre viva</Bold900M>
            </MobileTitle>


            <DownArrow onClick={() => scroolTo('sobre')}><img src={down_arrow} alt="" style={{ marginTop: '10%'}}/></DownArrow>
        </Respira>
     );
}
 
export default First;