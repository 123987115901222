import styled, {keyframes} from "styled-components";
import { HeaderDiv, HeaderLeftContainer, HeaderItem, HeaderItemText } from "../styles/styles";
import bracell_logo from './../assets/bracell_logo.png'
// import hamburger from './../assets/hamburger.png'
import { useState } from "react";
import hamburger from  './../assets/hamburger.svg'

const Header = () => {

    const scroolTo = (id) => {
        document.getElementById(id).scrollIntoView({ 
            behavior: 'smooth' 
        });
    }

    const HeaderRightMobile = styled.div`
        display: flex;
        // @media (max-width: 1100px) {
            display: flex;
            flex-direction: row;
            width: 55%;
            height: 100%;
            justify-content: flex-end;
            align-items: center;
        // }
        @media (min-width: 1100px) {
            margin-right: 10%;
        }
    `

    const keyframe = keyframes`
        0% {
            right: -100%;
        }
        100% {
            right: 0;
        }
    `

    const [showMobileMenu, setShowMobileMenu] = useState(false);

    
    const openHamburger = () => {
        setShowMobileMenu(true);
        document.getElementById('root').style.cssText = 'position: fixed;';
    }

    const closeHamburger = () => {
        document.getElementById('root').style.cssText = 'position: relative;';
        document.getElementById('menu').style.cssText = `transform: translateX(100%); transition: 0.5s;`;
        setTimeout(() => {
            setShowMobileMenu(false);
        }, 500);
    }

    const Vector = styled.img`
        cursor: pointer;
        position: absolute;
        width: 40px;
        top: 40px;
        @media (min-width: 1100px) {
            top: 20px !important;
            margin-right: 40px !important;
            z-index: 5;
        }
    `


    const MobileMenu = styled.div`
        @media (min-width: 1100px) {
            display: flex;
            width: 400px;
            flex-direction: column;
            height: 100vh;
            border-radius: 0px;
            opacity: 0.8;
            z-index: 5;
            background-color: #1c1c1c;
            position: fixed;
            top: 0;
            right: 0;
            animation: ${keyframe} 0.5s;
        }
        @media (max-width: 1100px) {
            display: flex;
            width: 290px;
            flex-direction: column;
            height: 330px;
            border-radius: 15px;
            opacity: 0.95;
            z-index: 5;
            background-color: white;
            position: fixed;
            top: 0;
            right: 0;
            animation: ${keyframe} 0.5s ease-in-out;
        }
        @media (min-width: 768px) and (max-width: 1100px) {
            width: 560px;
            
        }
    `

    const PopupItemText = styled(HeaderItemText)`
    @media (max-width: 1100px) {
        color: black;
    }
    @media(min-width: 1100px) {
        width: 400px;
        color: white;
        text-transform: uppercase;
    }
        `


    const SpecialDiv = styled.div`
        @media (max-width: 1100px) {
            margin-top: 30px;
        }
        @media (min-width: 1100px) {
            margin-top: 250px;
        }
    `

    const OnlyMobileImg = styled.img`
        @media (min-width: 1100px) {
            display: none;
        }
        @media (max-width: 1100px) {
            display: flex;
        }
    `

    const OnlyMobileP = styled.p`
        @media (min-width: 1100px) {
            display: none;
        }
    `

    return ( 
        <HeaderDiv>
            {showMobileMenu && 
            <div>
                <div onClick={() => closeHamburger()} style={{cursor: 'pointer', width: '100vw', height: '100vh', position: 'absolute', top: '0', left: '0', backgroundColor: 'rgba(0, 0, 0, 0.3)', zIndex: '2'}}/>
                <MobileMenu id='menu'>
                    <div style={{display: 'flex', width: '100%', marginTop: '30px', flexDirection: 'column', alignItems: 'center'}}>
                        <OnlyMobileImg src={bracell_logo} alt="" width='150px' />
                    </div>
                    <div style={{display: 'flex', width: '100%', marginTop: '30px', justifyContent: 'space-around', flexDirection: 'row', alignItems: 'center'}}>
                        <OnlyMobileP style={{fontSize: '20px', fontWeight:'800'}}>Menu</OnlyMobileP>
                        <OnlyMobileP style={{fontSize: '20px', fontWeight:'800'}} onClick={() => closeHamburger()}>X</OnlyMobileP>
                    </div>
                    <SpecialDiv style={{display: 'flex', width: '55%', justifyContent: 'space-around', flexDirection: 'column', height: '120px', marginLeft: '45px', alignItems: 'flex-start'}}>
                        <HeaderItem onClick={() =>  {closeHamburger(); scroolTo('sobre')}}><PopupItemText >Sobre o Projeto</PopupItemText></HeaderItem>
                        <HeaderItem onClick={() => {closeHamburger(); scroolTo('conheca')}}><PopupItemText>Conheça a floresta</PopupItemText></HeaderItem>
                        <HeaderItem onClick={() =>  {closeHamburger(); scroolTo('surpreenda')}}><PopupItemText>Se Surpreenda</PopupItemText></HeaderItem>
                        <HeaderItem onClick={() =>  {closeHamburger(); scroolTo('biodiversidade')}}><PopupItemText>Biodiversidade em números</PopupItemText></HeaderItem>
                    </SpecialDiv>
                </MobileMenu>
                </div>}
            <HeaderLeftContainer>
                {/* <img src={bracell_logo} alt="" width="100px" style={{filter: 'brightness(0) invert(1)'}}/> */}
            </HeaderLeftContainer>
            {/* <HeaderRightContainer>
                <HeaderItem onClick={() => scroolTo('conheca')}><HeaderItemText>Conheça a floresta</HeaderItemText></HeaderItem>
                <HeaderItem onClick={() => scroolTo('sobre')}><HeaderItemText>Sobre o Projeto</HeaderItemText></HeaderItem>
                <HeaderItem onClick={() => scroolTo('surpreenda')}><HeaderItemText>Se Surpreenda</HeaderItemText></HeaderItem>
            </HeaderRightContainer> */}
            <HeaderRightMobile>
                <Vector src={hamburger} onClick={() => showMobileMenu ? closeHamburger() : openHamburger()}/>
            </HeaderRightMobile>
        </HeaderDiv>
     );
}
 
export default Header;