import { ThirdDiv, ThirdSquareR, ThirdTextsContainer, ThirdTextsP} from './../styles/styles';
// import sobre_esq_fundo from './../assets/sobre_esq_fundo.png';
import folha from './../assets/folha.jpg';
// import sobre_esq_topo from './../assets/sobre_esq_topo.png';
// import sobre_dir_topo from './../assets/sobre_dir_topo.png';
import styled from 'styled-components';
import { Parallax, ParallaxProvider }  from 'react-scroll-parallax';
import Fade from 'react-reveal/Fade';

const Third = () => {

    // const Image = styled.img`
    //     position: absolute;
    //     z-index: -1;
    //     heigth: 489px;
    //     width: 50%;
    //     @media (max-width: 1100px) {
    //         width: 100%;
    //         height: 300px;
    //         margin-left: 50%;
    //     }
    // `

    // const Flower = styled.img`
    //     position: absolute;
    //     z-index: -1;
    //     width: 50%;
    //     @media (max-width: 1100px) {
    //         width: 100%;
    //         height: 300px;
    //         margin-left: 50%;
    //     }
    //     @media(min-width: 1400px) {
    //         height: 450px;
    //     }
    // `


    // const Shadow = styled.img`
    //     position: absolute;
    //     z-index: -1;
    //     heigth: 100%;
    //     width: 51%;
    //     margin-left: -1%;
    //     @media (max-width: 1100px) {
    //         width: 100%;
    //         height: 459px;
    //         margin-left: 0%;
    //         margin-top: -100px;
    //     }
    // `   

    const SpecialImage = styled.img`
        position: absolute;
        z-index: -2;
        width: 100%;	
        // height: 500px;
        // margin-left: -1%
        margin-top: -150px;
        @media (max-width: 1100px) {
            // width: 100%;
            height: 500px;
        }
    `

    return ( 
        <ParallaxProvider>
        <ThirdDiv id='sobre'>
            {/* <ThirdSquareL>
                <Image src={sobre_esq_fundo} alt="" />
                <Flower src={sobre_esq_topo} alt="" style={{zIndex: '1'}} />
            </ThirdSquareL> */}
            <ThirdSquareR style={{overflow: 'hidden'}}>
                <Parallax speed={-10}>
                    <div style={{width: '100vw', height:'100%'}}>
                        <SpecialImage src={folha} alt="" />
                    </div>
                </Parallax>

                {/* <Shadow src={sobre_dir_topo} alt="" /> */}
                {/* <ThirdTexts> */}
                    {/* <ThirdTextsTitle><span style={{color: '#efefef'}}>SOBRE</span> O PROJETO</ThirdTextsTitle> */}
                    <Parallax speed={0} style={{width: '100vw', display: 'flex', justifyContent: 'center'}}>
                        <Fade top  distance={"30px"}>

                        <ThirdTextsContainer>
                            <ThirdTextsP>O projeto <span style={{fontWeight: '700'}}>Floresta Sempre Viva</span> nasceu em 2019, como um livro fotográfico apresentando a biodiversidade presente nas áreas de conservação da Bracell a partir do olhar de quem vive e monitora as nossas áreas florestais. </ThirdTextsP>

                            <ThirdTextsP style={{marginTop: '20px'}}>Localizadas no Litoral Norte e Agreste da Bahia, as reservas da Bracell retratam as belezas da Mata Atlântica e a importância ecossistêmica de sua preservação.</ThirdTextsP>
                        </ThirdTextsContainer>
                        </Fade>
                    </Parallax>
                {/* </ThirdTexts> */}
            </ThirdSquareR>
        </ThirdDiv>
        </ParallaxProvider>
     );
}
 
export default Third;
