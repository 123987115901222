import { Conheca, SquareTextM, SquareTextT, SquareTextS, LinesU, LinesD, PlusSign, MainTitleDivM, MainTitleText, PopUpDiv, PopUpDiv2 } from "../styles/styles";
import conheca1 from "../assets/conheca1.png";
import conheca2 from "../assets/conheca2.png";
import conheca3 from "../assets/conheca3.png";
import conheca4 from "../assets/conheca4.png";  
import playerImg from "../assets/player.svg";
import styled from "styled-components";
import { useState } from "react";

import seta_direita from "../assets/seta_direita.png";
import seta_esquerda from "../assets/seta_esquerda.png";

import eventoimg from "../assets/evento.jpeg";
import one from "../assets/galeria/one.jpg";
import two from "../assets/galeria/two.jpg";
import three from "../assets/galeria/three.jpg";
import four from "../assets/galeria/four.jpg";
import five from "../assets/galeria/five.jpg";
import six from "../assets/galeria/six.jpg";
import seven from "../assets/galeria/seven.jpg";
import eight from "../assets/galeria/eight.jpg";
import nine from "../assets/galeria/nine.jpg";
import ten from "../assets/galeria/ten.jpg";
import eleven from "../assets/galeria/eleven.jpg";
import twelve from "../assets/galeria/twelve.jpg";
import thirteen from "../assets/galeria/thirteen.jpg";
import fourteen from "../assets/galeria/fourteen.jpg";
import fifteen from "../assets/galeria/fifteen.jpg";
import sixteen from "../assets/galeria/sixteen.jpg";
import seventeen from "../assets/galeria/seventeen.jpg";
import eighteen from "../assets/galeria/eighteen.jpg";
import nineteen from "../assets/galeria/nineteen.jpg";
import twenty from "../assets/galeria/twenty.jpg";
import twentyone from "../assets/galeria/twentyone.jpg";
import twentytwo from "../assets/galeria/twentytwo.jpg";

import { Parallax, ParallaxProvider } from "react-scroll-parallax";
import Fade from 'react-reveal/Fade';

const Second = () => {

    const PopupIframe = styled.iframe`
        position: absolute;
        top: 0;
        left: 0;
        width: 90%;
        height: 90%;
        border: none;
        margin: 3% 0 0 3%;
        zIndex: 2;
        @media (max-width: 1100px) {
            width: 80%;
            height: 40vh;
            margin-top: 30vh;
            margin-left: 10%;
        }
    `

    const GaleryImages = styled.img`
        height: 150px;
        width: 150px;
        object-fit: cover;
        overflow: hidden;
        cursor: pointer;
        @media (max-width: 1100px) {
            width: 50px;
            height: 50px;
        }
    `

    const MainPhoto = styled.img`
        height: 340px;
        @media (max-width: 1100px) {
            height: auto;
            width: 75vw;
        }
    `
    const MainPhoto2 = styled.img`
        height: 550px;
        @media (max-width: 1100px) {
            width: 100%;
            height: auto;
            max-height: 900px;
            max-width: 600px;
            margin: auto;
        }
        @media (min-width: 1440px) {
            width: 100%;
            height: 800px
        }
    `

    const OtherDiv = styled.div`
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        width: 90%;
        overflow: auto;
        @media (max-width: 1100px) {
            display: none;
        }
    `

    const OtherPhotos = styled.img`
        height: 40px;
        margin: 5px;
        cursor: pointer;
    `

    const GalleryPhotoDiv = styled.div`
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100px;   
        height: 100px;
        overflow: hidden;
        margin: 40px 10px;
        @media (max-width: 1100px) {
            height: 50px;
            margin: 10px;
            width: 50px;
    `
        
    const [mainPic, setMainPic] = useState(null);

    var photoArray = [one, two, three, four, five, six, seven, eight, nine, ten, eleven, twelve, thirteen, fourteen, fifteen, sixteen, seventeen, eighteen, nineteen, twenty, twentyone, twentytwo];

    const handleMainPic = (photo) => {
        setMainPic(photo);
    }

    const handleSlidePhoto = (idx) => {
        var current = photoArray.indexOf(mainPic);
        var next = current + idx;
        if (next < 0) {
            next = photoArray.length - 1;
        }
        if (next > photoArray.length - 1) {
            next = 0;
        }
        setMainPic(photoArray[next]);
    }

    const GalleryRow = styled.div`
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 22%;
        align-items: center;
        justify-content: center;
        @media (max-width: 1100px) {
            flex-direction: row;
            width: 70vw;
            height: 20vh;
            flex-wrap: wrap;
            overflow: hidden;
        }
    `
    const GalleryDiv = styled.div`
        z-index: 5;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        margin-top: -50px;
        align-items: center;
        justify-content: center;
        @media (max-width: 1100px) {
            margin-top: -250px;
        }
    `

    
    var cliquesContent = ""

    if(mainPic === null) {
        cliquesContent = (
            <GalleryDiv>
                <MainTitleText style={{color: "#EFEFEF", marginBottom: '50px', marginTop: '-100px'}}>
                    Galeria de Fotos  
                </MainTitleText>
                <GalleryRow>
                    <GalleryPhotoDiv><GaleryImages src={one} alt="" onClick={() => handleMainPic(one)}/></GalleryPhotoDiv>
                    <GalleryPhotoDiv><GaleryImages src={two} alt="" onClick={() => handleMainPic(two)}/></GalleryPhotoDiv>
                    <GalleryPhotoDiv><GaleryImages src={three} alt="" onClick={() => handleMainPic(three)}/></GalleryPhotoDiv>
                    <GalleryPhotoDiv><GaleryImages src={four} alt="" onClick={() => handleMainPic(four)}/></GalleryPhotoDiv>
                    <GalleryPhotoDiv><GaleryImages src={five} alt="" onClick={() => handleMainPic(five)}/></GalleryPhotoDiv>
                    <GalleryPhotoDiv><GaleryImages src={six} alt="" onClick={() => handleMainPic(six)}/></GalleryPhotoDiv>
                </GalleryRow>
                <GalleryRow>
                    <GalleryPhotoDiv><GaleryImages src={seven} alt="" onClick={() => handleMainPic(seven)}/></GalleryPhotoDiv>
                    <GalleryPhotoDiv><GaleryImages src={eight} alt="" onClick={() => handleMainPic(eight)}/></GalleryPhotoDiv>
                    <GalleryPhotoDiv><GaleryImages src={nine} alt="" onClick={() => handleMainPic(nine)}/></GalleryPhotoDiv>
                    <GalleryPhotoDiv><GaleryImages src={ten} alt="" onClick={() => handleMainPic(ten)}/></GalleryPhotoDiv>
                    <GalleryPhotoDiv><GaleryImages src={eleven} alt="" onClick={() => handleMainPic(eleven)}/></GalleryPhotoDiv>
                    <GalleryPhotoDiv><GaleryImages src={twelve} alt="" onClick={() => handleMainPic(twelve)}/></GalleryPhotoDiv>
                </GalleryRow>
                <GalleryRow>
                    <GalleryPhotoDiv><GaleryImages src={thirteen} alt="" onClick={() => handleMainPic(thirteen)}/></GalleryPhotoDiv>
                    <GalleryPhotoDiv><GaleryImages src={fourteen} alt="" onClick={() => handleMainPic(fourteen)}/></GalleryPhotoDiv>
                    <GalleryPhotoDiv><GaleryImages src={fifteen} alt="" onClick={() => handleMainPic(fifteen)}/></GalleryPhotoDiv>
                    <GalleryPhotoDiv><GaleryImages src={sixteen} alt="" onClick={() => handleMainPic(sixteen)}/></GalleryPhotoDiv>
                    <GalleryPhotoDiv><GaleryImages src={seventeen} alt="" onClick={() => handleMainPic(seventeen)}/></GalleryPhotoDiv>
                    <GalleryPhotoDiv><GaleryImages src={eighteen} alt="" onClick={() => handleMainPic(eighteen)}/></GalleryPhotoDiv>
                </GalleryRow>
            </GalleryDiv>
        );
    }
    else {
        cliquesContent = (
            <GalleryDiv>
                <MainTitleText style={{color: "#EFEFEF", marginBottom: '50px', marginTop: '-100px'}}>
                    Galeria de Fotos  
                </MainTitleText>
                <div style={{display: 'flex', width: '90%', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: '40px'}}>
                    <img style={{cursor:'pointer'}} src={seta_esquerda} alt='seta' onClick={() => handleSlidePhoto(-1)}/>
                    <MainPhoto src={mainPic} alt="" />
                    <img style={{cursor:'pointer'}} src={seta_direita} alt='seta' onClick={() => handleSlidePhoto(1)}/>
                </div>
                <OtherDiv className="other-div">
                    {photoArray.map((photo) => {
                        var otherPic = "";
                        if(photo !== mainPic) {
                            otherPic = (<OtherPhotos src={photo} alt="" onClick={() => handleMainPic(photo)}/>)
                        }
                        else{
                            otherPic =  (<OtherPhotos src={photo} style={{border: '1px solid #fff'}} alt="" onClick={() => handleMainPic(photo)}/>)
                        }
                        return otherPic;
                    })}
                </OtherDiv>
            </GalleryDiv>)
    }
 
    const porDentroContent = (
        <div style={{marginTop: '-50px', width: '100%', height: '80%', display: 'flex', alignItems:'center', justifyContent:'space-around', flexDirection:'column'}}>
            <PopupIframe
                src={`https://www.youtube.com/embed/PfjEeWdEOyw`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Embedded youtube"
            />
        </div>)
    
    const programasContent = (
        <div style={{marginTop: '-50px', width: '100%', height: '80%', display: 'flex', alignItems:'center', justifyContent:'space-around', flexDirection:'column'}}>
            <PopupIframe
                src={`https://www.youtube.com/embed/o73YTwj1Q2Q`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Embedded youtube"
            />
        </div>)

        const flagraAnimal = (
            <div style={{marginTop: '-50px', width: '100%', height: '80%', display: 'flex', alignItems:'center', justifyContent:'space-around', flexDirection:'column'}}>
                <PopupIframe
                    src={`https://www.youtube.com/embed/videoseries?list=PLCfCq9L_Bz3E6ZQo1SmrICNwQV3GpqWLZ`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="Embedded youtube"
                />
            </div>) 

        const evento = (
            <div style={{marginTop: '0', width: 'calc(100% - 50px)', height: 'calc(100% - 50px) ', display: 'flex', justifyContent:'center', flexDirection:'column'}}>
                <MainPhoto2 src={eventoimg} alt="" />
            </div>) 



    return ( 
        <Conheca id='conheca'>
        <PopUp plusContent={evento}></PopUp>
            <MainTitleDivM>
                <MainTitleText style={{color: "#5A954C"}}>CONHEÇA A</MainTitleText>
                <MainTitleText style={{color: "#EFEFEF"}}>FLORESTA</MainTitleText>
            </MainTitleDivM>
            <LinesU>
                <Square popup={true} margin='15px' height='290px' width='540px' textT="Por dentro da floresta" textM="Faça um passeio virtual de 360º em nossas áreas de preservação ambiental" image={conheca2} widthBox='40%' mainTitle={true} player={true} plusContent={porDentroContent}/>
                <Square popup={true} height='360px' width='410px' textM="Programas ambientais da Bracell na região do Litoral Norte e Agresta Baiano." image={conheca1} widthBox='47%' mainTitle={false} player={true} plusContent={programasContent}/>
            </LinesU>
            <LinesD>
                <Square popup={true} margin='22px' height='260px' width='488px' textT="A floresta em cliques" textM="Veja os melhores registros fotográficos da biodiversidade conservada em nossas áreas na Bahia" image={conheca3} widthBox='60%' mainTitle={false} plusContent={cliquesContent} player={false}/>
                <Square popup={true} margin='35px' height='230px' width='488px' textT="Flagra animal" textM="Confira um pouco da fauna presente na região" textS="Acesse nosso canal" image={conheca4} widthBox='40%' mainTitle={false} player={false} plusContent={flagraAnimal} />
            </LinesD>
        </Conheca>
     );
}

const PopUp = (props) => {
    const [showPopUp, setShowPopUp] = useState(false);

    const FshowPopUp = () => {   
        if(props.popup){
            setShowPopUp(true);
        }
    }

    const FhidePopUp = () => {
        setShowPopUp(false);
    }

    const CloseButton = styled.div`
        position: absolute;
        font-size: 32px;
        color: #EFEFEF;
        top: 0;
        right: 0;
        z-index: 2;
        margin-top: 10px;
        margin-right: 20px;
        cursor: pointer;
        @media (max-width: 1100px) {

            margin-right: 10px;
        }
    `

    const plusContent = props.plusContent;

    return (
        <div>
            {showPopUp &&
                <div>    
                    <div onClick={FhidePopUp} style={{ position: 'fixed', top:'0', left: '0', width: '100vw', height: '100%', zIndex: '9'}} />
                    <PopUpDiv2 id='eventopopup'>
                        <div style={{width: '100%', height: '50px',  position:'relative'}}><CloseButton onClick={FhidePopUp}>X</CloseButton></div>
                        {plusContent}
                    </PopUpDiv2>
                </div>
            }
            </div>
    )
}
const Square = (props) => {

    const width = props.width;
    const textT = props.textT;
    const textM = props.textM;
    const textS = props.textS;
    const image = props.image;
    const height = props.height;
    const margin = props.margin;
    const player = props.player;

    const plusContent = props.plusContent;
    const [showPopUp, setShowPopUp] = useState(false);

    const FshowPopUp = () => {   
        if(props.popup){
            setShowPopUp(true);
        }
        else{
            window.open('https://www.youtube.com/channel/UCNWL4AL-JDYiLF5IHhCZbjA', '_blank');
        }
    }

    const FhidePopUp = () => {
        setShowPopUp(false);
    }


    const ImageStyled = styled.img`
        position: absolute;
        zIndex: 0;
        top: 0;
        left: 0;
        cursor: pointer;
        margin-left: -1px;
        @media (max-width: 1100px) {
            width: 275px;
            margin-right: 30px;
            height: 160px;
            margin: 40px 0px 0px 0px;
        }
        @media (max-width: 1100px) and (min-width: 768px) {
            width: 450px;
            height: 250px;
        }
    ` 

    const SquareDiv = styled.div`
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
        width: ${width};
        height: ${height};
        cursor: pointer;
        margin-left: ${margin};
        margin: 10px 20px 0 20px;
        position: relative; 
        @media (max-width: 1100px) {
            margin: 0;
            justify-content: flex-end;
            align-items: flex-end;
            margin-left: calc((50% - 180px) / 2);
            margin-right: 0;
            width: 300px;
            height: 200px;
        }
        @media (max-width: 1100px) and (min-width: 768px) {
            width: 520px;
            justify-content: flex-end;
            align-items: flex-end;
            height: 300px;
            margin-left: calc((50% - 325px) / 2);
        }
        &:hover {
            transform: scale(1.06);
        }
        transition: 0.5s all;
    `
    
    const SquareText = styled.div`
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        position: absolute;
        align-items: flex-start;
        background-color: #ffffff;
        z-index: 1;
        margin-bottom: 31px;
        width: 40%;
        padding: 10px;
        @media (max-width: 1100px) {
            margin-top: calc(20% - 30px);
            margin-left: 150px;
            width: 175px;
            padding: 5px 5px 5px 8px;
        }
    `


    const PlayerImage = styled.img`
        position: absolute;
        z-index: 2;
        opacity: 0.8;
        top: 0;
        left: right;
        margin-top: 5%;
        margin-right: 80%;
        @media (max-width: 1100px) {
            margin-top: 50px;
            margin-right: 85%;
            width: 32px;
        }
    `

    const CloseButton = styled.div`
        position: absolute;
        font-size: 32px;
        color: #EFEFEF;
        top: 0;
        right: 0;
        z-index: 2;
        margin-top: 10px;
        margin-right: 20px;
        cursor: pointer;
        @media (max-width: 1100px) {

            margin-right: 10px;
        }
    `

    return (
        <div>
            {showPopUp &&
                <div>    
                    <div onClick={FhidePopUp} style={{ position: 'fixed', top:'0', left: '0', width: '100vw', height: '100vh', zIndex: '9'}} />
                    <PopUpDiv id='popup'>
                        <div style={{width: '100%', height: '100%',  position:'relative'}}><CloseButton onClick={FhidePopUp}>X</CloseButton></div>
                        {plusContent}
                    </PopUpDiv>
                </div>
            }
        <ParallaxProvider>
            <Fade top cascade distance={"30px"}>
                <Parallax speed={props.parallaxSpeed}>

                <SquareDiv onClick={FshowPopUp}>
                    <ImageStyled src={image} alt={image} />
                    {player && <PlayerImage src={playerImg} onClick={FshowPopUp} alt=''  />}
                    <SquareText>
                        <SquareTextT>{textT}</SquareTextT>
                        {props.strongText && <SquareTextM><strong>{props.textMStrong}</strong> {textM}</SquareTextM>}
                        {!props.strongText && <SquareTextM>{textM}</SquareTextM>}
                        <SquareTextS>{textS}</SquareTextS>
                    </SquareText>
                    <PlusSign>+</PlusSign>
                </SquareDiv>
                </Parallax>
            </Fade>
        </ParallaxProvider>
        </div>
    )
}




export default Second;