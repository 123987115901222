import { WhiteStripDiv, WhiteStripItemDiv, WhiteStripTitle, WhiteStripText } from "../styles/styles";
import turtle from './../assets/turtle.svg';
import monkey from './../assets/macaco.svg';
import bush from './../assets/arbusto.svg';
import Fade from 'react-reveal/Fade';

const WhiteStrip = () => {
    return ( 
        <WhiteStripDiv>
            <Fade top  distance={"30px"} >
                <WhiteStripItem image={turtle} title='+570' preBold="animais reintroduzidos à seu " bold="habitat natural" posBold=", desde 2020"/>
                <WhiteStripItem image={monkey} title='3 áreas' preBold="de Solturas de " bold="Animais Silvestres" posBold=" regularizadas junto ao INEMA"/>
                <WhiteStripItem image={bush} title='+60mil' preBold="hectares de " bold="áreas conservadas" posBold=" pela Bracell"/>
            </Fade>
        </WhiteStripDiv>
     );
}

export const WhiteStripItem = (props) => {
    const image = props.image;
    const title = props.title;
    const preBold = props.preBold;
    const bold = props.bold;
    const posBold = props.posBold;
    return (
        <WhiteStripItemDiv style={{padding: '0 0 50px'}}>
            <img src={image} alt="" style={{width: '70px', height: '70px'}}/>
            <WhiteStripTitle>{title}</WhiteStripTitle>
            <WhiteStripText>{preBold}<span style={{fontWeight: '800'}}>{bold}</span>{posBold}</WhiteStripText>
        </WhiteStripItemDiv>
    )
}
 
export default WhiteStrip;