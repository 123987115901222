import styled from 'styled-components';
import bracell_logo from '../assets/bracell_logo.png';
import { FooterDiv, FooterLogo, FooterLogoImage, FooterLinks, FooterReserved, FooterLinksDiv } from '../styles/styles';
import facebook from '../assets/facebook.svg';
import linkedin from '../assets/linkedin.svg';
import instagram from '../assets/instagram.svg';
import youtube from '../assets/youtube.svg';

const Footer = () => {

    const FooterLinksSection = styled.div`
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 40%;
        margin: 2%;
        justify-content: space-around;
        @media (max-width: 1100px) {
            width: 65%;
            margin: 0;
        }
    `

    return ( 
        <FooterDiv>
            <FooterLogo><FooterLogoImage src={bracell_logo} alt="" /></FooterLogo>
            <FooterLinksDiv>
                <FooterLinksSection>
                    <FooterLinks href="https://www.facebook.com/SomosBracell/"><img src={facebook}  width='12px' alt='facebook'/></FooterLinks>
                    <FooterLinks href="https://www.instagram.com/somosbracell/"><img src={instagram} width='20px' alt='instagram'/></FooterLinks>
                    <FooterLinks href="https://www.linkedin.com/company/bracell/"><img src={linkedin} width='20px' alt='linkedin'/></FooterLinks>
                    <FooterLinks href="https://www.youtube.com/channel/UCNWL4AL-JDYiLF5IHhCZbjA"><img width='20px' src={youtube} alt='youtube'/></FooterLinks>
                </FooterLinksSection>
                <FooterLinksSection>
                    <FooterLinks href="https://www.bracell.com">NOSSO SITE OFICIAL</FooterLinks>
                </FooterLinksSection>
            </FooterLinksDiv>
            <FooterReserved>© 2022 por Bracell. Todos os direitos reservados.</FooterReserved>
        </FooterDiv>
     );
}


export default Footer;