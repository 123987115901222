import styled, { keyframes } from "styled-components"

export const Respira = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media (max-width: 1100px) {
        // height: 27.5vh;
        height: 100vh;
    }
    background:url( data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAAXNSR0IArs4c6QAAABVJREFUGFdjZEADjFB+A4zGKQDXCAA97gIFY+m2hQAAAABJRU5ErkJggg==) repeat;
`

export const Image = styled.img`
    height: 100vh;
    position: absolute;
    top: 0;
    // margin-left: -7.5vw;
    min-width: 100vw;
    left: 0;
    z-index: -1;
    @media (max-width: 768px) {
        margin-left: 0;
        height: 30vh;
        display: none;
    }
    @media(min-width: 2100px) {
        width: 100vw;
        height: 200vh;
        margin-left: 0;
        margin-top: -65vh;
    }
    @media(min-width: 950px) and (max-width: 1100px){
        width: 150vw;
        margin-left: -25vw;
        display: none;
    }
`

export const MainGIF = styled.img`
    // width: 100vw;
    // height: 30vh; /
    height: 100vh;
    display: none;
    position: absolute;
    margin-left: -50%;
    top: 0;
    left: 0;
    z-index: -1;
    @media (max-width: 1100px) {
        display: block;
    }
    `

export const Title = styled.div`
    width: 50%;
    height: 20%;
    margin-left: 12.5%;
    color: #ffffff;
    display: flex;
    align-items: center;
    @media (max-width: 1100px) {
        display: none !important;
    }
`

export const Bold900 = styled.h1`
    font-size: 64px;
    font-weight: 900;
    margin-right: 10px;
    @media (max-width: 1100px) {
        font-size: 21px;
    }
`

export const Bold900M = styled.h1`
display: none;
color: #fff;
font-size: 42px !important;
font-weight: 900;
margin-right: 10px;
@media (max-width: 1100px) {
    font-size: 21px;
    display: inline;
}
`

export const HeaderDiv = styled.div`
    width: 100vw;
    height: 50px;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: row;
`

export const HeaderLeftContainer = styled.div`
    width: 40%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const HeaderRightContainer = styled.div`
    width: 60%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    @media (max-width: 1100px) {
        display: none;
    }
`

export const HeaderItem = styled.div`
    padding: 10px;
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 18px;
    color: #ffffff;
    @media(min-width: 1100px) {
        // background-color: red;
        transition: 0.3s;
        cursor: pointer;
        margin-left: -40px;
        padding: 20px;
        &:hover {
            background-color: #689040;
        }

    }

`

export const HeaderItemText = styled.span`
    font-size: 14px;
    font-weight: 500;
    text-decoration: none;
    color: #ffffff;
    cursor: pointer;
`

export const Conheca = styled.div`
    background-color: #101612;
    width: 100vw;
    padding: 3vh 0;
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media (max-width: 1100px) {
        justify-content: center;
        width: 100vw;
        padding: 0;
        height: 1000px;
    }
    @media (max-width: 1100px) and (min-width: 768px) {
        height: 1300px;
    }

`

export const SquareTextT = styled.h1`
    font-size: 14px;
    font-weight: 700;
    color: #0D120E;
    @media (max-width: 1100px) {
        font-size: 14px;
    }
`

export const SquareTextM = styled.h1`
    font-size: 12px;
    font-weight: 400;
    color: #005B45;
    @media (max-width: 1100px) {
        font-size: 12px;
    }
`

export const SquareTextS = styled.h1`
    font-size: 12px;
    font-weight: 500;
    font-style: italic;
    color: #0D120E;
    cursor: pointer;
    @media (max-width: 1100px) {
        font-size: 8px;
    }
`

export const LinesU = styled.div`
    display: flex;
    width: 1300px;
    flex-direction: row-reverse;
    height: 60%;
    justify-content: center; 
    align-items: flex-end;
    margin-top: 40px;
    @media (max-width: 1100px) {
        width: 100vw;
        height: 400px;
        flex-direction: column;
        align-items: center;
        margin-top: 50px;
        justify-content: flex-end;
    }
    @media (max-width: 1100px) and (min-width: 768px) {
        height: 600px;
    }
`   

export const LinesD = styled.div`
    display: flex;
    width: 1300px;
    flex-direction: row;
    height: 60%;
    justify-content: center; 
    align-items: flex-start;
    margin-top: 40px;
    @media (max-width: 1100px) {
        width: 100vw;
        height: 400px;
        margin-top: 0px;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
    }
    @media (max-width: 1100px) and (min-width: 768px) {
        height: 600px;
        margin-top: 0px;
    }
`  

export const PlusSign = styled.span`
    font-size: 20.5px;
    font-weight: 300;
    border: 1.34px solid #efefef;
    color: #efefef;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    &:hover {
        background-color: #efefef;
        color: #0D120E;
        font-weight: 500;
    }
    @media (max-width: 1100px) {
        margin-top: 145px;
        margin-left: 310px;
    }
`

export const MainTitleDivM = styled.div`
    display: flex;
    flex-direction: column;
    width: fit-content;
    padding: 30px;
    padding-bottom: 0px;
    margin-left: -800px;
    // text-align: center;
    background-color: #101612;
    z-index: 4;
    @media (max-width: 1100px) {
        padding: 20px 30px;
        position: absolute;
        top: 0;
        left: 0;
        margin-left: 12%;
        margin-top: 30px;
    }
`   

export const MainTitleText = styled.h1`
    font-size: 32px;
    font-weigth: 400;
    @media (max-width: 1100px) {
        font-size: 22px;
    }
`

export const ThirdDiv = styled.div`
    width: 100vw;
    display: flex;
    heigth: fit-content;
    flex-direction: row;
    justify-content: center;
`

// export const ThirdSquareL = styled.div`
//     width: 50%;
//     height: 350px;
//     display: flex;
//     flex-direction: column;
//     justify-content: flex-start;
//     @media (max-width: 1100px) {
//         height: 350px;
//     }
//     @media (max-width: 1100px) {
//         display: none;
//     }
// `

export const ThirdSquareR = styled.div`
    width: 100vw;
    height: 350px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media (max-width: 1100px) {
        height: 300px;
        width: 100vw;
    }
`

export const ThirdTexts = styled.div`
    width: 100%;
    height: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

export const ThirdTextsContainer = styled.div`
    display: flex;
    width: 1100px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(16, 22, 18, 0.8);
    padding: 32px;
    text-align: center;
    @media (max-width: 1100px) {
        width: 90vw;
        height: fit-content;
        margin-left: 0vw;
        padding: 8px;
        // margin-top: 17px;
    }
`

export const ThirdTextsP = styled.span`
    font-size: 20px;
    font-weight: 400;
    color: white;
    line-spacing: 15px;
    word-spacing: 15px;
    @media (max-width: 1100px) {
        line-spacing: 10px;
        word-spacing: 10px;
        font-size: 15px;
    }
`

export const WhiteStripDiv = styled.div`
    height: 250px;
    width: 100%;
    background-color: #efefef;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    @media (max-width: 1100px) {
        height: 900px;
        flex-direction: column;
        justify-content: space-around;
    }
`
export const WhiteStripItemDiv = styled.div`
    height: 100%;
    width: 223px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin: 0px;
`

export const WhiteStripTitle = styled.h1`
    font-size: 40px;
    font-weight: 800;
    color: #5A954C;
`

export const WhiteStripText = styled.span`
    font-size: 15px;
    color: #0D120E;
    font-weight: 400;
    text-align: center;
`

export const FifthSpans = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media (max-width: 1100px) {
        height: 280px;
        margin-top: -70px;
    }
    @media (max-width: 1100px) and (min-width: 768px) {
        margin-top: -200px;
    }
`
export const FifthDiv = styled.div`
    width: 100vw;
    height: 500px;
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin-top: -7px;
    background-color: #101612;
    padding-top: 20px;
    @media (max-width: 1100px) {
        height: 300px;
        // background-color: red;
    }
    @media (min-width: 768px) and (max-width: 1100px) {
        height: 500px;
    }
`

export const FifthText = styled.span`
    font-size: 45px;
    font-weight: 900;
    text-align: center;
    color: #DCDCDC;
    width: 680px;
    @media (max-width: 1100px) {
        font-size: 20px;       
    }
`

export const FifthSmallText = styled.span`
    font-size: 18px;
    font-weight: 400;
    text-align: center;
    color: #DCDCDC;
    width: 500px;
    margin-top: 20px;
    @media (max-width: 1100px) {
        font-size: 12px;
    }
`

export const SixthDiv = styled.div`
    width: 100vw;
    height: 1050px;
    display: flex;
    flex-direction: row;
    margin-top: -7px;
    background-color: #101612;
    @media (max-width: 1100px) {
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
    }
`

export const LeftDiv = styled.div`
    width: 50%;
    height: 1000px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    z-index: 2;
    @media (max-width: 1100px) {
        margin-top: -550px; 
        width: 100vw;
        height: 1300px;
        align-items: center;
    }
    @media (max-width: 1100px) and (min-width: 768px) {
        margin-top: -200px;
        justify-content: flex-start;
        width: 100vw;
        height: 1500px;
        align-items: center;
        margin-left: 0;
    }
`

export const Surpreenda = styled.div`
    width: 100vw;
    height: 1700px;
    display: flex;
    flex-direction: column;
    background-color: #101612;
    @media (max-width: 1100px) {
        align-items: center;
        width: 100vw;
    }
    @media (max-width: 1100px) and (min-width: 768px) {
        height: 2450px;
    }

`

export const LeftSpans = styled.div`
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 160px;
    justify-content: space-around;
    margin: 30px 18% -10px 15%;
    margin-bottom: 7%;
    @media (max-width: 1100px) {
        width: 100vw;
        margin: 0;
        margin-top: 50px;
        height: 90px;
        align-items: flex-start;
        margin-left: calc(100vw - 290px);
        margin-bottom: 10px;
    }
    @media (max-width: 1100px) and (min-width: 768px) {
        margin-left: calc(100vw - 520px);
        margin-bottom: 50px;
    }
`

export const LeftText = styled.span`
    font-size: 24px;
    font-weight: 400;
    color: #efefef;
    width: 120%;
    @media (max-width: 1100px) {
        font-size: 12px;
        width: fit-content;
        text-align: flex-start;
    }
    @media (max-width: 1100px) and (min-width: 768px) {
        font-size: 18px;
    }
`

export const LeftTitle = styled.span`
    font-size: 32px;
    font-weight: 700;
    color: #5A954C;
    @media (max-width: 1100px) {
        font-size: 17px;
    }
    @media (max-width: 1100px) and (min-width: 768px) {
        font-size: 22px;
    }
`

export const RightDiv = styled.div`
    width: 50%;
    height: 1000px;
    display: flex;
    margin-top: 25px;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    z-index: 2;
    margin-left: 35px;
    @media (max-width: 1100px) {
        margin-top: -50px;
        margin-left: 0;
        width: 100vw;
        align-items: center;
        height: 400px;
        justify-content: center;
    }
    @media (max-width: 1100px) and (min-width: 768px) {
        margin-top: 0px;
        margin-left: 0;
    }
`

export const QuoteDiv = styled.div`
    width: 68%;
    height: 22%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-bottom: 15%;
    margin-left: 50px;
    @media (max-width: 1100px) {
        margin-top: -20%;
        display: none;
    }
`

export const QuoteDivMobile = styled.div`
    width: 68%;
    height: 200px;
    display: none;
    flex-direction: column;
    justify-content: space-around;
    margin-bottom: 15%;
    @media (max-width: 1100px) {
        display: flex;
        margin-left: 20%;
        margin-top: 10%;
    }
    @media (max-width: 1100px) and (min-width: 768px) {
        width: 60%;
    }
`

export const Quote = styled.span`
    font-size: 29px;
    font-weight: 300;
    color: #D5DDD6;
    font-style: italic;
    @media (max-width: 1100px) {
        font-size: 18px;
    }
    @media (max-width: 1100px) and (min-width: 768px) {
        font-size: 24px;
    }
`

export const QuoteAuthor = styled.span`
    font-size: 19px;
    font-weight: 600;
    color: #D5DDD6;
    font-style: italic;
    margin-top: 10px;
    font-family: "Azo-Sans", sans-serif;
    @media (max-width: 1100px) {
        font-size: 12px;
    }
    @media (max-width: 1100px) and (min-width: 768px) {
        font-size: 16px;
    }
`
export const SeventhDiv = styled.div`
    width: 100vw;
    height: 700px;
    display: flex;
    flex-direction: row;
    @media (max-width: 1100px) {
        flex-direction: column;
        height: 800px;
        margin-top: 75px;
        width: 100vw;
    }
    @media (max-width: 1100px) and (min-width: 768px) {
        margin-top: 150px;
        height: 800px;
        align-items: center;
        width: 100vw;
    }
`

export const SevenLeft = styled.div`
    width: 45%;
    height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding-top: 8%;
    align-items: center;
    margin-top: 100px;
    z-index: 2;
    @media (max-width: 1100px) {
        width: 100vw;
        height: 150px;
        margin-top: -75px;
        margin-left: -27.5%;
        align-items: flex-start;
        justify-content: center;
    }
    @media (max-width: 1100px) and (min-width: 768px) {
        width: 100vw;
        margin-top: 0px;
        align-items: center;
        margin-left: -275px;
        height: 200px;
    }
`

export const SevenRight = styled.div`
    width: 55%;
    height: 600px;
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    justify-content: space-around;
    align-items: flex-start;
    z-index: 2;
    @media (max-width: 1100px) {
        width: 100vw;
        height: 500px;
        margin: -100px 0 0 0;
        justify-content: center;
        align-items: flex-start;
    }
    @media (max-width: 1100px) and (min-width: 768px) {
        height: 500px;
        margin-bottom: 0px;
        margin-top: -150px;
        justify-content: space-around;
        align-items: center;
    }
`

export const SevenLeftTitle = styled.span` 
    font-size: 32px;
    font-weight: 700;
    color: #5A954C;
    @media (max-width: 1100px) {
        align-items: center;
        font-size: 17px;
        margin-left: 7.5%;
    }
    @media (max-width: 1100px) and (min-width: 768px) {
        margin-left: 0;
        width: 500px;
        margin-top: 50px;
    }
`

export const SevenLeftText = styled.span`
    font-size: 24px;
    font-weight: 400;
    color: #efefef;
    width: 90%;
    @media (max-width: 1100px) {
        margin-left: 7.5%;
        font-size: 12px;
        width: 70%;
    }
    @media (max-width: 1100px) and (min-width: 768px) {
        font-size: 16px;
        margin-left: 0;
        width: 500px;
    }
`

export const SevenRightText = styled.span`
    font-size: 24px;
    font-weight: 400;
    color: #efefef;
    width: 66%;
    @media (max-width: 1100px) {
        font-size: 15px;
        width: 60%;
        margin-top: 10%;
        margin-left: 15%;
    }
    @media (max-width: 1100px) and (min-width: 768px) {
        margin-top: -30px;
        font-size: 18px;
    }
`

export const BaixarButton = styled.div`
    width: 150px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 5px;
    color: #efefef;
    border: 1px solid #efefef;
    font-weight: 700;
    font-size: 24px;
    transition: 0.3s ease all;
    cursor: pointer;
    &:hover {
        background-color: #efefef;
        color: #242B26;
        transform: translateY(5px);
    }
    @media (max-width: 1100px) {
        width: 120px;
        height: 30px;
        margin-top: 5%;
        font-size: 12px;
        margin-left: 15%;
    }
    @media (max-width: 1100px) and (min-width: 768px) {
        margin-left: -300px;
    }
`

export const EndingDiv = styled.div`
    width: 100vw;
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background-color: #101612;
    @media (max-width: 1100px) {
        width: 100vw;
        padding: 50px 0;
        justify-content: space-evenly;
    }
    @media (max-width: 1100px) and (min-width: 768px) {
        height: 50px;
    }
`

export const EndingTextDiv = styled.div`
    width: 46%;
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-left: 17%;
    @media (max-width: 1100px) {
        width: 90%;
        margin-left: 5%;
    }
`

export const EndingText = styled.span`
    font-size: 24px;
    font-weight: 400;
    color: #efefef;
    line-height: 1.5;
    @media (max-width: 1100px) {
        font-size: 16px;
        width: 86%;
        margin-left: 7%;
    }
`

export const EndingButton = styled.div`
    width: 250px;
    height: 40px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border: 1px solid #ffffff;
    border-radius: 12px;
    color: #ffffff;
    margin-left: 17%;
    font-weight: 400;
    font-size: 21px;
    cursor: pointer;
    margin-top: 2%;
    transition: 0.3s ease all;
    &:hover {
        background-color: #ffffff;
        color: #242B26;
    }
    @media (max-width: 1100px) {
        width: 180px;
        height: 30px;
        margin-left: 10%;
        border-radius: 5px;
        font-size: 15px;
    }
`

export const FooterDiv = styled.div`
    width: 100vw;
    height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    background-color: #f5f5f5;
`
export const FooterLogo = styled.div`
    width: 100%;
    height: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1%;
    justify-content: space-around;
    @media (max-width: 1100px) {
        width: 128px;
    }
`

export const FooterLogoImage = styled.img`
    width: 216px;
    @media (max-width: 1100px) {
        width: 128px;
    }
`

export const FooterLinksDiv = styled.div`
    width: 70%;
    height: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    @media (max-width: 1100px) {
        width: 100%;
        height: 50%;
        flex-direction: column;
    }
`

export const FooterLinks = styled.a`
    text-decoration: none;
    color: #383838;
    font-size: 16px;
    font-weight: 700;
    padding: 0 10%;
    flex-wrap: no  wrap;
    @media (max-width: 1100px) {
        font-size: 12px;
    }
`

export const FooterReserved = styled.div`
    width: 100%;
    height: 50%;
    display: flex;
    text-align: center;
    flex-direction: column;
    @media (max-width: 1100px) {
        font-size: 8px;
    }
`

export const rotateAnimation = keyframes`
    0%, 10% {
        transform: translateY(0);
    }
    14%,26% {
        transform: translateY(-80px);
     }
    30%,42% {
        transform: translateY(-155px);
    }
    46%,58% {
        transform: translateY(-240px);
    }
    62%, 70% {
        transform: translateY(-320px);
    }
    72%, 100% {
        transform: translateY(-400px);
    }
`

export const mobileKeyframes = keyframes`
    0%, 10% {
        transform: translateY(0);
    }
    14%,26% {
        transform: translateY(-26px);
     }
    30%,42% {
        transform: translateY(-50px);
    }
    46%,58% {
        transform: translateY(-75px);
    }
    62%, 70% {
        transform: translateY(-100px);
    }
    72%, 100% {
        transform: translateY(-125px);
    }
`

export const CarrouselDiv = styled.div`
    position: absolute;
    height: 80px;
    overflow: hidden;
    margin-left: 375px;
    @media (max-width: 1100px) {
        margin-left: 125px;
        height: 26px;
    }

`

export const Carrousel = styled.div`
    display: flex; 
    flex-direction: column;
    justify-content: center;
    position: relative;
    overflow: hidden;
    animation-name: ${rotateAnimation};
    animation-duration: 12s;
    animation-iteration-count: infinite;
    @media (max-width: 1100px) {
        animation-name: ${mobileKeyframes};
    }
`

const UpAndDown = keyframes`
    0% {
        transform: translateY(0);
    }
    4% {
        transform: translateY(10px);
    }
    100% {
        transform: translateY(0);
    }
`

export const DownArrow = styled.a`
    position: absolute;
    margin-top: 80vh;
    left: calc(50% - 31px);
    width: 62px;
    height: 62px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    animation-name: ${UpAndDown};
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    @media (max-width: 1100px) {
        // display: none;
        margin-top: 70vh;
    }
`

export const PopUpDiv = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 950px;
    height: 560px;
    margin-top: calc(50vh - 280px);
    margin-left: calc(50% - 475px);
    background-color: rgba(0, 0, 0, 0.9);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 10;
    @media (max-width: 1100px) {
        width: 100%;
        height: 100%;
        margin-top: 0;
        margin-left: 0;
    }
`

export const PopUpDiv2 = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 500px;
    height: 650px;
    margin-top: calc(50vh - 300px);
    margin-left: calc(50% - 250px);
    background-color: rgba(0, 0, 0, 0.9);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 10;
    @media (max-width: 1100px) {
        width: 100%;
        height: 100vh;
        margin-top: 0;
        margin-left: 0;
    }
    @media (min-width: 1440px) {
        width: 700px;
        height: 900px;
        margin-top: calc(50vh - 450px);
        margin-left: calc(50% - 350px);
    }
`
export const CarrouselItem = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;

`

export const CarrouselText = styled.h1`
    font-size: 64px;
    font-weight: 500;
    @media (max-width: 1100px) {
        font-size: 21px;
    }
`

export const FlorestaCarrousel = styled.div`
    display: flex;
    width: 380px;
    overflow: hidden;
`