import {WhiteStripDiv, WhiteStripTitle, WhiteStripText, WhiteStripItemDiv, MainTitleDivM, MainTitleText} from "../styles/styles";
import frog from './../assets/sapinho.svg';
import snake from './../assets/cobrinha.svg';
import gorila from './../assets/macaquin.svg';
import bird from './../assets/passarin.svg';
import bush from './../assets/flora.svg';
import styled from "styled-components";
import Fade from 'react-reveal/Fade';

const SecondWS = () => {
    const WhiteStripDivA = styled(WhiteStripDiv)`
        position: relative;
        align-items: center;
        // justify-content: space-between;
        height: 200px;
        @media (max-width: 1100px) {
            height: 1250px;
            width: 100vw;
            justify-content: space-around;
            // background-color: red;
        }
    `

    const WhiteStripDivB = styled(WhiteStripDiv)`
        flex-direction: column;
        height: 360px;
        @media (max-width: 1100px) {
            height: 1350px;
            justify-content: flex-end;
            margin-top: 0px;
            position: relative;
        }
        `

    const MainTitleDivMA = styled(MainTitleDivM)`
        background-color: rgba(255, 255, 255, 00);
        @media (max-width: 1100px) {
            margin-top: 40px;
        }
        `

    return ( 
        
        <WhiteStripDivB id='biodiversidade'>
            <MainTitleDivMA>
                <MainTitleText style={{color: "#5A954C"}}>BIODIVERSIDADE</MainTitleText>
                <MainTitleText style={{color: "#1C1C1C"}}>EM NÚMEROS</MainTitleText>
            </MainTitleDivMA>
            <WhiteStripDivA>
            <Fade top>
                <WhiteStripItem image={frog} title='66' preBold="espécies de anfíbios "/>
                <WhiteStripItem image={snake} title='76' preBold="espécies de répteis "/> 
                <WhiteStripItem image={gorila} title='41' preBold="espécies de mamíferos "/>
                <WhiteStripItem image={bird} title='277' preBold="espécies de aves "/>
                <WhiteStripItem image={bush} title='408' preBold="espécies de flora "/>
            </Fade>
            </WhiteStripDivA>
        </WhiteStripDivB>
     );
}

export const WhiteStripItem = (props) => {
    const image = props.image;
    const title = props.title;
    const preBold = props.preBold;

    const WhiteStripItemDivA = styled(WhiteStripItemDiv)`
    @media (min-width: 1100px) {
        width: 50%;
    }
    `

    const WhiteStripTextA = styled(WhiteStripText)`
    @media (min-width: 1100px) {
        width: 70%;
    }
    `

    return (
        <WhiteStripItemDivA>
            <img src={image} alt="" />
            <WhiteStripTitle>{title}</WhiteStripTitle>
            <WhiteStripTextA>{preBold}</WhiteStripTextA>
        </WhiteStripItemDivA>
    )
}
 
 
export default SecondWS;